<template>
  <article class="vwid">
    <Hero heading="Power the full potential of your Volkswagen ID.4" :subheading="true">
      <template slot="subheading">
        <span class="sub-heading"
          >Conquer the roads ahead with the benefits included in your ID.4 complimentary charging bundle.
        </span>
      </template>
      <template slot="image">
        <picture>
          <img alt="" :src="require('@/assets/images/VWId/vwid1.webp')" />
        </picture>
      </template>
    </Hero>
    <section class="evolve">
      <div class="container">
        <div class="flex-row">
          <h2>
            Evolve with<br />
            Electrify Canada
          </h2>
          <div class="right">
            <p style="margin-bottom: 45px">
              Electrify Canada is committed to building a network of convenient and reliable electric vehicle charging
              stations—at strategic locations in metropolitan centres, along major highways and near popular
              amenities—to help get electric vehicle (EV) drivers where they want to go. Because we know your ID.4
              wasn’t made to stay in the garage.
            </p>
            <PartnerDropdown id="id4-select" v-model="id4YearChosen" :options="id4DropdownOptions">
              <template slot="label">
                <div id="partner-label-container">
                  <label for="id4-select"> Select your Volkswagen ID.4™ model year:</label>
                </div>
              </template>
            </PartnerDropdown>
            <p v-if="id4YearChosen == '2021'">
              <strong
                >Your model year 2021 or model year 2022 ID.4 comes with an available 3 years of unlimited free charging
                energy beginning upon vehicle purchase!
                <ReferenceLink num="1"
                  >Available on model year 2021 and model year 2022 ID.4 vehicles. 3 years of unlimited charging begins
                  upon vehicle purchase. Non-transferable. Not available for commercial use, such as ridesharing.
                  Requires Electrify Canada account, app, enrollment in ID.4 Charging Plan, and acceptance of Terms of
                  Use. After user-initiated charge session stop or vehicle reaches full charge or charging otherwise
                  stops, and 10-min grace period, Idle Fees will apply and user is responsible. In the event of
                  suspected fraud or abuse or other unforeseen events, Electrify Canada may discontinue or modify the
                  offer in its sole discretion.
                </ReferenceLink></strong
              >
            </p>
            <p v-if="id4YearChosen == '2023'">
              <strong
                >Your model year 2023 or model year 2024 ID.4 comes with 1,400 kWh of DC fast charging available for
                four years from date of purchase!
                <ReferenceLink num="2"
                  >Available on model year 2023 and model year 2024 ID.4 vehicles. 1,400 kWh of DC fast charging
                  available for four years from the date of vehicle purchase. Non-transferable. Not available for
                  commercial use, such as ridesharing. Requires Electrify Canada account, app, enrollment in ID.4
                  Charging Plan, and acceptance of Terms of Use. After user-initiated charge session stop or vehicle
                  reaches full charge or charging otherwise stops, and 10-min grace period, Idle Fees will apply and
                  user is responsible. In the event of suspected fraud or abuse or other unforeseen events, Electrify
                  Canada may discontinue or modify the offer in its sole discretion.
                </ReferenceLink></strong
              >
            </p>
            <p v-if="id4YearChosen == '2023'">
              Compatible users of the 2023-2024 ID.4 Charging Plan will be able to
              <strong>activate Plug&Charge functionality</strong>
              for use with this plan (see plan enrollment steps below for more information).
            </p>
            <p>With these benefits, ID.4 drivers can enjoy the full potential of electric freedom.</p>
            <router-link class="button" :to="{ name: 'locate-charger-en' }">Locate a Charger</router-link>
          </div>
        </div>
      </div>
    </section>
    <section class="pt-20">
      <div class="img">
        <div class="container">
          <picture>
            <img loading="lazy" alt="" :src="require('@/assets/images/VWId/vwid2.webp')" />
          </picture>
        </div>
      </div>
      <div class="content">
        <div class="container">
          <div class="flex-row">
            <h2>Go with freedom</h2>
            <div class="right">
              <p>
                Get going with Electrify Canada’s DC Fast Charging network. Enrollment in the ID.4 Charging Plan is
                simple. Just follow these steps:
              </p>
              <div>
                <b>Step 1:</b> Download the Electrify Canada app
                <ReferenceLink num="3">{{ $t('messagingAndDataRates') }} </ReferenceLink>, create your account and
                select “Set Up Public Charging.”
              </div>
              <div class="mt-10">
                <b>Step 2:</b> Select “Choose Membership Plan” then “Premium Offers.”
                <ul class="sub-li step">
                  <li class="list-style">
                    Existing Electrify Canada members may select “Premium Offers” in the Account tab, then “I have an
                    enrollment code.”
                  </li>
                </ul>
              </div>
              <div class="mt-10">
                <b>Step 3:</b>
                Input your 6-digit enrollment code, which you will receive from your ID.4 dealer, and based on your ID.4
                model year, select either the 2021-2022 or 2023-2024 ID.4 Charging Plan. Input your vehicle 17-digit
                <span class="wrap-last-two-words">
                  VIN
                  <ReferenceLink num="4">{{ $t('vinLocation') }}</ReferenceLink></span
                >
                for verification.
              </div>
              <div class="mt-10"><b>Step 4:</b> Accept Plan Disclosure, then tap “Select this plan.”</div>
              <div class="mt-10">
                <b>Step 5:</b> Enter payment info<ReferenceLink num="5"
                  >{{ $t('autoReloadDisclaimer') }}
                </ReferenceLink>
                and set up auto-reload.
                <ul class="sub-li step">
                  <li class="list-style">
                    You will need to enter a payment method<ReferenceLink num="5"
                      >{{ $t('autoReloadDisclaimer') }}
                    </ReferenceLink>
                    to cover any incurred Idle Fees<ReferenceLink num="6"
                      >Drivers who do not unplug and move their vehicle ten minutes after their charging session is
                      complete will be charged a $0.40 per-minute idle fee. This fee encourages drivers to move their
                      car promptly so that others can use the charger. You can be notified when your charging session is
                      complete by text message2 or see it on the charger screen.
                    </ReferenceLink>
                    or any applicable taxes.
                    <span v-if="id4YearChosen == '2023'">
                      (see your 2023-2024 ID.4
                      <router-link class="link" :to="{ name: '2023-2024-vw-id4-charging-plan-disclosure-en' }"
                        ><strong>Plan Disclosure</strong>
                      </router-link>
                      for more information)</span
                    >
                  </li>
                </ul>
              </div>
              <div class="mt-10" v-if="id4YearChosen == '2023'">
                <b>Step 6:</b>
                Plug&Charge, sign in to the Electrify Canada app, navigate to your “Account”, under your “Plans”, follow
                the prompts in the app to “Activate Plug&Charge”.
              </div>
              <p>
                Offer enrollment can be as smooth as your journeys, but if you encounter any roadblocks, call
                1-833-231-3226 to contact Electrify Canada Customer Assistance, available 24/7.
              </p>
              <div class="flex-row cta">
                <router-link class="button cta-link" :to="{ name: 'mobile-app-en' }">Download The App</router-link>
                <a
                  rel="noopener"
                  target="_blank"
                  class="cta-link"
                  href="https://apps.apple.com/ca/app/electrify-canada/id1472391910"
                  ><img
                    loading="lazy"
                    :alt="$t('downloadInTheAppStore')"
                    src="@/assets/images/Global/app-store-logo-en.png"
                /></a>
                <a
                  rel="noopener"
                  target="_blank"
                  class="cta-link"
                  href="https://play.google.com/store/apps/details?id=com.ec.evowner&hl=en_CA"
                  ><img
                    loading="lazy"
                    src="@/assets/images/Global/google-play-badge-en.png"
                    alt="Get it on Google Play"
                /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="pt-20" v-if="id4YearChosen == '2023'">
      <div class="img">
        <div class="container">
          <picture>
            <img loading="lazy" alt="" :src="require('@/assets/images/VWId/vwid3.webp')" />
          </picture>
        </div>
      </div>
    </section>
  </article>
</template>

<script>
import lang from '@/lang';
import Hero from '@/components/Hero/Hero.vue';
import ReferenceLink from '@/components/ReferenceLink.vue';
import PartnerDropdown from '@/components/PartnerDropdown.vue';

export default {
  name: 'VWId',
  metaInfo: {
    title: 'Electrify Canada MY 2021-2024 VW ID.4 Charging Program',
    meta: [
      {
        name: 'description',
        content: 'Electrify Canada MY 2021-2024 VW ID.4 Ultra-Fast Charging Program description and  information ',
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrify-canada.ca/vw-id4/' }],
  },
  components: { Hero, ReferenceLink, PartnerDropdown },
  i18n: {
    messages: {},
    sharedMessages: lang,
  },
  data() {
    return {
      id4DropdownOptions: [
        { text: 'Select...', value: null },
        { text: 'Model year 2021 and model year 2022 ID.4 vehicles', value: '2021' },
        { text: 'Model year 2023 and model year 2024 ID.4 vehicles', value: '2023' },
      ],
      id4DropdownOptionsFR: [
        { text: 'Sélectionnez...', value: null },
        { text: 'Modèles de véhicules ID.4 des années 2021 et 2022', value: '2021' },
        { text: 'Modèles de véhicules ID.4 des années 2023 et 2024', value: '2023' },
      ],
      id4YearChosen: null,
    };
  },
  computed: {
    isMobile() {
      return this.$resize && this.$mq.below(1130);
    },
    isPhone() {
      return this.$resize && this.$mq.below(750);
    },
  },
};
</script>
